import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyNameIs = _resolveComponent("MyNameIs")!
  const _component_CurriculoContato = _resolveComponent("CurriculoContato")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MyNameIs, { curriculo: _ctx.curriculo }, null, 8, ["curriculo"]),
    _createVNode(_component_CurriculoContato, { curriculo: _ctx.curriculo }, null, 8, ["curriculo"])
  ]))
}